import { colors } from "./colors"
import { space, fontSizes } from "./scales"
import { variants } from "./variants"

export const theme = {
    useCustomProperties: false,

    // Should introduce more ?
    breakpoints: ["40em", "56em", "64em"],

    colors: {
        ...colors,

        background: "white",
    },

    space,

    sizes: {
        ...space,
        full: "100%",
        screen: "100vh",
    },

    fonts: {
        alegreya: '"Alegreya", serif',
        heebo: '"Heebo", sans-serif',
    },

    fontSizes,

    fontWeights: {
        body: 400,
        bold: 700,
        black: 900,
    },

    lineHeights: {
        body: 1.5,
        heading: 1.25,
    },

    ...variants,
}
