export const colors = {
    // White - Black
    white: "#ffffff",
    black: "#000000",

    // Smash Delta
    sd: {
        black: "#121212",
        white: "#ffffff",
        blue: "#3353ff",
        orange: "#f14f1d",
        beige: "#eae4e4",
    },

    // Mission control
    mc: {
        green: "#0EDA83",
        // green: "#65ffb3",
        yellow: "#F1AF1B",
        red: "#F7625A",
        purple: "#6E1EFF",
        // purple: "#e288ff",
    },

    // tech
    tech: {
        //
        sick: "#881212",
        healthy: "#0EDA83",

        // Tech Cube
        interviews: "#FC6e22",
        bluetooth: "#440Bd4",

        personal: "#7FF026",
        aggregated: "#FD00F6",

        analysis: "#7f12ad",

        // normal
        normal: "#999999",
    },
}
