const SPACE_SCALE = 2
const FONT_SCALE = 1.25

export const spaceScale = n => Math.pow(SPACE_SCALE, n) + "rem"
export const fontScale = n => Math.pow(FONT_SCALE, n) + "rem"

export const space = {
    m: spaceScale(0),
    l: spaceScale(1),
    xl: spaceScale(2),
    xxl: spaceScale(3),
    xxxl: spaceScale(4),
    s: spaceScale(-1),
    xs: spaceScale(-2),
    xxs: spaceScale(-3),
    xxxs: spaceScale(-4),
}

export const fontSizes = {
    m: fontScale(0),
    l: fontScale(1),
    xl: fontScale(2),
    xxl: fontScale(3),
    xxxl: fontScale(4),
    xxxxl: fontScale(5),
    s: fontScale(-1),
    xs: fontScale(-2),
}
