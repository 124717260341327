export const variants = {
    text: {
        headline: {
            lineHeight: 1,
            fontFamily: "heebo",
            fontWeight: 900,
            textTransform: "uppercase",
            transform: `translateY(0.4rem)`,
            "&::before": {
                content: `''`,
                marginTop: "-0.7rem",
                display: "block",
                height: 0,
            },
        },

        subheadline: {
            lineHeight: 1.25,
            fontFamily: "alegreya",
            fontWeight: 700,
            transform: `translateY(0.4rem)`,
            "&::before": {
                content: `''`,
                marginTop: "-0.7rem",
                display: "block",
                height: 0,
            },
        },

        normal: {
            lineHeight: 1.6,
            fontFamily: "alegreya",
            fontWeight: 400,
            transform: `translateY(0.4rem)`,
            "&::before": {
                content: `''`,
                marginTop: "-0.8rem",
                display: "block",
                height: 0,
            },
        },

        h1: {
            variant: "text.headline",
            fontSize: ["xxl", "xxxl", null],
            color: "sd.blue",
        },

        h2: {
            variant: "text.headline",
            fontSize: ["xl", "xxl", null],
        },

        h3: {
            variant: "text.headline",
            fontSize: ["l", "xl", null],
        },

        sh1: {
            variant: "text.subheadline",
            fontSize: "xl",
            color: "sd.blue",
        },

        sh2: {
            variant: "text.subheadline",
            fontSize: "l",
        },

        sh3: {
            variant: "text.subheadline",
            fontSize: "m",
        },

        body: {
            variant: "text.normal",
            fontSize: "m",
        },

        "body-s": {
            variant: "text.normal",
            fontSize: "s",
        },

        callout: {
            variant: "text.subheadline",
            fontSize: "m",
        },

        footnote: {
            variant: "text.normal",
            fontSize: "xs",
            opacity: 0.5,
            letterSpacing: 0.2,
        },

        caption: {
            variant: "text.normal",
            fontSize: "s",
            opacity: 0.5,
        },

        link: {
            variant: "text.normal",
            textDecoration: "underline",
            lineHeight: 1,
            cursor: "pointer",
        },
    },

    flex: {
        center: {
            justifyContent: "center",
            alignItems: "center",
        },
    },

    layout: {
        container: {
            maxWidth: "80ch",
        },
        "inline-vis": {
            maxWidth: "120ch",
        },
    },

    position: {
        inset0: {
            position: "absolute",
            top: 0,
            left: 0,
            width: "full",
            height: "full",
        },
    },

    tasks: {
        completed: {
            borderBottom: "2px solid",
            borderColor: "mc.green",
            opacity: 0.5,
        },

        pending: {
            borderBottom: "2px solid",
            borderColor: "mc.purple",
        },
    },

    links: {
        styles: {
            a: {
                color: "inherit",
            },
        },
    },
}
